import * as React from 'react'

// export class AboutComponent {
  
//   render = () => {
//     return (
//       <div className="">About
//       </div>
//     );
//   }
// }

// export default AboutComponent;

const About = () => {
  return <div><h1>About</h1><p>We are a diverse, woman owned technology company<br></br>specializing in technology services for private and public sector clients.</p></div>
};

export default About;
