import * as React from 'react'

// export class HomeComponent {
  
//   render = () => {
//     return (
//       <div className="">Home</div>
//     );
//   }
// }

// export default HomeComponent;

const Home = () => {
  return <p id="tagline">Next Generation Technology Solutions</p>;
};

export default Home;
